<template>
  <div id="print">
    <p style="text-align: center; font-weight: 600">
      {{ orderData.customer }} {{orderData.adjust_orders?"调整单":"结算单"}}
      <template v-if="orderData.is_balance === 1">未完成</template>
    </p>
    <p style="width: 300px; float: left;">流水号：{{ orderData.record_no }}</p>
    <p v-if="orderData.currency === 'CNY'" style="width: 200px; float: right;">币种：{{ orderData.currency }}</p>
    <p v-else style="width: 200px; float: right;">汇率：{{ orderData.balanceExchangeRate }} 币种：{{ orderData.currency }}</p>
    <div v-if="orderData.currency === 'CNY'">
      <table>
        <tbody>
          <tr>
            <th rowspan="2">接单日期</th>
            <th rowspan="2">工作编号{{this.rowChargeProxyNumber}}</th>
            <th colspan="2" v-if="this.rowChargeClearNumber > 0">报关报检收入</th>
            <th colspan="2" v-if="this.rowChargeProxyNumber > 0">代理运输收入</th>
            <th colspan="2" v-if="this.rowChargeWmsNumber > 0">仓储包干收入</th>
            <th colspan="2" v-if="this.rowChargeRentNumber > 0">租赁服务收入</th>
            <th rowspan="2" v-if="chargeNumber>0">合计</th>
            <th colspan="3" v-if="this.rowPayClearNumber > 0">报关报检付出</th>
            <th colspan="3" v-if="this.rowPayProxyNumber > 0">代理运输付出</th>
            <th colspan="3" v-if="this.rowPayWmsNumber > 0">仓储包干付出</th>
            <th rowspan="2" v-if="payNumber>0">合计</th>
            <th rowspan="2" >利润</th>
          </tr>
          <tr>
            <th class="w50" v-if="this.rowChargeClearNumber > 0">名称</th>
            <th class="w70" v-if="this.rowChargeClearNumber > 0">金额</th>
            <th class="w50" v-if="this.rowChargeProxyNumber > 0">名称</th>
            <th class="w70" v-if="this.rowChargeProxyNumber > 0">金额</th>
            <th class="w50" v-if="this.rowChargeWmsNumber > 0">名称</th>
            <th class="w70" v-if="this.rowChargeWmsNumber > 0">金额</th>
            <th class="w50" v-if="this.rowChargeRentNumber > 0">名称</th>
            <th class="w70" v-if="this.rowChargeRentNumber > 0">金额</th>
            <th class="w50" v-if="this.rowPayClearNumber > 0">名称</th>
            <th class="w70" v-if="this.rowPayClearNumber > 0">金额</th>
            <th class="w100" v-if="this.rowPayClearNumber > 0">付款对象</th>
            <th class="w50" v-if="this.rowPayProxyNumber > 0">名称</th>
            <th class="w70" v-if="this.rowPayProxyNumber > 0">金额</th>
            <th class="w100" v-if="this.rowPayProxyNumber > 0">付款对象</th>
            <th class="w50" v-if="this.rowPayWmsNumber > 0">名称</th>
            <th class="w70" v-if="this.rowPayWmsNumber > 0">金额</th>
            <th class="w100" v-if="this.rowPayWmsNumber > 0">付款对象</th>
          </tr>
          <tr v-for="item in orderList" :key="item.biz_num"><!-- 去掉class="tbody"样式，每页不空行-->
            <td >{{ moment(item.record_item).format('YYYY/MM/DD') }}</td>
            <td style="word-wrap:break-word">{{ item.biz_num }}</td>
            <td colspan="2" v-if="rowChargeClearNumber&&rowChargeClearNumber > 0">
              <table class="table2" >
                <tbody v-if="item.fee_class_fees.charge_clear_fee">
                  <tr v-for="chargeWmsFee in item.fee_class_fees.charge_clear_fee" :key="chargeWmsFee.id">
                    <td class="w50"><span class="fixwidth50">{{ chargeWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(chargeWmsFee.amount) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="2" v-if="rowChargeProxyNumber > 0">
              <table class="table2" >
                <tbody v-if="item.fee_class_fees.charge_proxy_fee">
                  <tr v-for="chargeWmsFee in item.fee_class_fees.charge_proxy_fee" :key="chargeWmsFee.id">
                    <td class="w50"><span class="fixwidth50">{{ chargeWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(chargeWmsFee.amount) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="2" v-if="rowChargeWmsNumber > 0">
              <table class="table2" >
                <tbody v-if="item.fee_class_fees.charge_wms_fee">
                  <tr v-for="chargeWmsFee in item.fee_class_fees.charge_wms_fee" :key="chargeWmsFee.id">
                    <td class="w50"><span class="fixwidth50">{{ chargeWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(chargeWmsFee.amount) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="2" v-if="rowChargeRentNumber > 0">
              <table class="table2" >
                <tbody v-if="item.fee_class_fees.charge_rent_fee">
                  <tr v-for="chargeRentFee in item.fee_class_fees.charge_rent_fee" :key="chargeRentFee.id">
                    <td class="w50"><span class="fixwidth50">{{ chargeRentFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(chargeRentFee.amount) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="w50 textRight" v-if="item.rowCharge>0 || item.rowCharge.length>6">{{ item.rowCharge }}</td>
            <td colspan="3" v-if="rowPayClearNumber>0">
              <table class="table2" >
                <tbody v-if="item.fee_class_fees.pay_clear_fee">
                  <tr v-for="payWmsFee in item.fee_class_fees.pay_clear_fee" :key="payWmsFee.id">
                    <td class="w50"><span class="fixwidth50">{{ payWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(payWmsFee.amount) }}</td>
                    <td class="w100"><span class="fixwidth100">{{ payWmsFee.pay_target }}</span></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="3" v-if="rowPayProxyNumber>0">
              <table class="table2" >
                <tbody v-if="item.fee_class_fees.pay_proxy_fee">
                  <tr v-for="payWmsFee in item.fee_class_fees.pay_proxy_fee" :key="payWmsFee.id">
                    <td class="w50"><span class="fixwidth50">{{ payWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(payWmsFee.amount) }}</td>
                    <td class="w100"><span class="fixwidth100">{{ payWmsFee.pay_target }}</span></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="3" v-if="rowPayWmsNumber>0">
              <table class="table2" >
                <tbody v-if="item.fee_class_fees.pay_wms_fee">
                  <tr v-for="payWmsFee in item.fee_class_fees.pay_wms_fee" :key="payWmsFee.id">
                    <td class="w50"><span class="fixwidth50">{{ payWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(payWmsFee.amount) }}</td>
                    <td class="w100"><span class="fixwidth100">{{ payWmsFee.pay_target }}</span></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="w50 textRight" v-if="item.rowPay>0 || item.rowPay.length>6">{{ item.rowPay }}</td>
            <td class="w50 textRight" v-else-if="payNumber>0"></td>
            <td class="w50 textRight">{{ moneyFormatter(item.profit) }}</td>
          </tr>
          <tr> <!-- 去掉class="tbody"样式，每页不空行-->
            <td colspan="2">合计</td>
            <!-- 报关报检合计-->
            <td colspan="2" class="textRight" v-if="this.rowChargeClearNumber > 0">{{ rowChargeClear }}</td>
            <!-- 代理运输合计-->
            <td colspan="2" class="textRight" v-if="this.rowChargeProxyNumber > 0">{{ rowChargeProxy }}</td>
            <!-- 仓储包干合计-->
            <td colspan="2" class="textRight" v-if="this.rowChargeWmsNumber > 0">{{ rowChargeWms }}</td>
            <!-- 租赁服务合计-->
            <td colspan="2" class="textRight" v-if="this.rowChargeRentNumber > 0">{{ rowChargeRent }}</td>
            <!-- 应收合计-->
            <td class="textRight" v-if="chargeNumber>0">{{ charge }}</td>
            <!-- 报关报检合计-->
            <td colspan="3" class="textRight" v-if="this.rowPayClearNumber > 0">{{ rowPayClear }}</td>
            <!-- 代理运输合计-->
            <td colspan="3" class="textRight" v-if="this.rowPayProxyNumber > 0">{{ rowPayProxy }}</td>
            <!-- 仓储包干合计-->
            <td colspan="3" class="textRight" v-if="this.rowPayWmsNumber > 0">{{ rowPayWms }}</td>
            <!-- 应付合计-->
            <td class="textRight" v-if="payNumber>0">{{ pay }}</td>
            <!-- 利润合计-->
            <td class="textRight">{{ profit }}</td>
          </tr>
        </tbody>
      </table>
      <p>开票备注：{{ orderData.apply_remark }}</p>
    </div>
    <div v-else>
      <table>
        <tbody>
          <tr>
            <th rowspan="2">接单日期</th>
            <th rowspan="2">工作编号</th>
            <th colspan="3">报关报检收入</th>
            <th colspan="3">代理运输收入</th>
            <th colspan="3">仓储包干收入</th>
            <th rowspan="2" >合计</th>
            <th colspan="4">报关报检付出</th>
            <th colspan="4">代理运输付出</th>
            <th colspan="4">仓储包干付出</th>
            <th rowspan="2" >合计</th>
            <th rowspan="2" >利润</th>
          </tr>
          <tr>
            <th class="w40">名称</th>
            <th class="w70">原金额</th>
            <th class="w70">人民币</th>
            <th class="w40">名称</th>
            <th class="w70">原金额</th>
            <th class="w70">人民币</th>
            <th class="w40">名称</th>
            <th class="w70">原金额</th>
            <th class="w70">人民币</th>
            <th class="w40">名称</th>
            <th class="w70">原金额</th>
            <th class="w70">人民币</th>
            <th class="w50">付款对象</th>
            <th class="w40">名称</th>
            <th class="w70">原金额</th>
            <th class="w70">人民币</th>
            <th class="w50">付款对象</th>
            <th class="w40">名称</th>
            <th class="w70">原金额</th>
            <th class="w70">人民币</th>
            <th class="w50">付款对象</th>
          </tr>
          <tr v-for="item in orderList" :key="item.biz_num">
            <td class="w60" style="word-wrap:break-word;">{{ moment(item.record_item).format('YYYY/MM/DD') }}</td>
            <td class="w60" style="word-wrap:break-word;">{{ item.biz_num }}</td>
            <td colspan="3">
              <table class="table2">
                <tbody v-if="item.fee_class_fees.charge_clear_fee">
                  <tr v-for="chargeWmsFee in item.fee_class_fees.charge_clear_fee" :key="chargeWmsFee.id">
                    <td class="w40"><span class="fixwidth40">{{ chargeWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(chargeWmsFee.amount) }}</td>
                    <td class="w70 textRight">{{ moneyFormatter(chargeWmsFee.exchange_amount) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="3">
              <table class="table2">
                <tbody v-if="item.fee_class_fees.charge_proxy_fee">
                  <tr v-for="chargeWmsFee in item.fee_class_fees.charge_proxy_fee" :key="chargeWmsFee.id">
                    <td class="w40"><span class="fixwidth40">{{ chargeWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(chargeWmsFee.amount) }}</td>
                    <td class="w70 textRight">{{ moneyFormatter(chargeWmsFee.exchange_amount) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="3">
              <table class="table2">
                <tbody v-if="item.fee_class_fees.charge_wms_fee">
                  <tr v-for="chargeWmsFee in item.fee_class_fees.charge_wms_fee" :key="chargeWmsFee.id">
                    <td class="w40"><span class="fixwidth40">{{ chargeWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(chargeWmsFee.amount) }}</td>
                    <td class="w70 textRight">{{ moneyFormatter(chargeWmsFee.exchange_amount) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="w50 textRight" style="white-space: nowrap;">{{ item.rowCharge }}</td>
            <td colspan="4">
              <table class="table2">
                <tbody v-if="item.fee_class_fees.pay_clear_fee">
                  <tr v-for="payWmsFee in item.fee_class_fees.pay_clear_fee" :key="payWmsFee.id">
                    <td class="w40"><span class="fixwidth40">{{ payWmsFee.fee_name }}</span></span></td>
                    <td class="w70 textRight">{{ moneyFormatter(payWmsFee.amount) }}</td>
                    <td class="w70 textRight">{{ moneyFormatter(payWmsFee.exchange_amount) }}</td>
                    <td class="w50"><span class="fixwidth50">{{ payWmsFee.pay_target }}</span></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="4">
              <table class="table2">
                <tbody v-if="item.fee_class_fees.pay_proxy_fee">
                  <tr v-for="payWmsFee in item.fee_class_fees.pay_proxy_fee" :key="payWmsFee.id">
                    <td class="w40"><span class="fixwidth40">{{ payWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(payWmsFee.amount) }}</td>
                    <td class="w70 textRight">{{ moneyFormatter(payWmsFee.exchange_amount) }}</td>
                    <td class="w50"><span class="fixwidth50">{{ payWmsFee.pay_target }}</span></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td colspan="4">
              <table class="table2">
                <tbody v-if="item.fee_class_fees.pay_wms_fee">
                  <tr v-for="payWmsFee in item.fee_class_fees.pay_wms_fee" :key="payWmsFee.id">
                    <td class="w40"><span class="fixwidth40">{{ payWmsFee.fee_name }}</span></td>
                    <td class="w70 textRight">{{ moneyFormatter(payWmsFee.amount) }}</td>
                    <td class="w70 textRight">{{ moneyFormatter(payWmsFee.exchange_amount) }}</td>
                    <td class="w50"><span class="fixwidth50">{{ payWmsFee.pay_target }}</span></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="w50 textRight">{{ item.rowPay }}</td>
            <td class="w50 textRight">{{ moneyFormatter(item.profit) }}</td>
          </tr>
          <tr>
            <td colspan="2">合计</td>
            <!-- 报关报检合计-->
            <td colspan="3" class="textRight">{{ rowChargeClear }}</td>
            <!-- 代理运输合计-->
            <td colspan="3" class="textRight">{{ rowChargeProxy }}</td>
            <!-- 仓储包干合计-->
            <td colspan="3" class="textRight">{{ rowChargeWms }}</td>
            <!-- 租赁服务合计-->
            <td colspan="3" class="textRight">{{ rowChargeRent }}</td>
            <!-- 应收合计-->
            <td class="textRight">{{ charge }}</td>
            <!-- 报关报检合计-->
            <td colspan="4" class="textRight">{{ rowPayClear }}</td>
            <!-- 代理运输合计-->
            <td colspan="4" class="textRight">{{ rowPayProxy }}</td>
            <!-- 仓储包干合计-->
            <td colspan="4" class="textRight">{{ rowPayWms }}</td>
            <!-- 应付合计-->
            <td class="textRight">{{ pay }}</td>
            <!-- 利润合计-->
            <td class="textRight">{{ profit }}</td>
          </tr>
        </tbody>
      </table>
      <p>开票备注：{{ orderData.apply_remark }}</p>
    </div>
    <p id="footer">
      <span>开票人：{{ userInfo.name }}</span>
      <span>业务员：{{ orderData.applicant_name }} </span>
      <span>公司经理：张丽青</span>
      <span>{{ date }}</span>
    </p>
    <div v-if="addFeeList&&addFeeList.length > 0">
      <p style="text-align: left; font-weight: 600">
        <a-divider orientation="left">
          后补费用明细
        </a-divider>
      </p>
      <a-table size="small" bordered :columns="addFeesColumns" :data-source="addFeeList" rowKey="fee_id" :pagination="false">
      </a-table>
    </div>
    <div v-for="adjust in orderData.adjust_orders" :key="adjust.id">
      <p style="text-align: center; font-weight: 800;">
        <a-divider orientation="center" style="font-size: 18px">
          {{ adjust.quota_num }} 调整单{{ orderData.fms_sap_num ? '（SAP编号:' + orderData.fms_sap_num + '）' : '' }}
        </a-divider>
      </p>
      <div v-for="detail in adjust.adjust_details" :key="detail.id">
        <p style="text-align: left; font-weight: 600">
          <a-divider orientation="left">
            {{ detail.adjust_type }}
          </a-divider>
        </p>
        <table v-if="detail.adjust_type === '删除费用'|| detail.adjust_type === '新增费用'|| detail.adjust_type === '加入结算单'">
          <tbody>
            <tr>
              <th>费用类型</th>
              <th>费用名称</th>
              <th>币种</th>
              <th>金额</th>
              <th>付款对象</th>
              <th>客户名称</th>
            </tr>
            <tr v-for="fee in detail.delete_fees" :key="fee.id">
              <td class="w100">{{ fee.fee_type?(fee.fee_type===1?'应收' : '应付'):'' }}</td>
              <td class="w100">{{ fee.fee_name }}</td>
              <td class="w100">{{ fee.currency_name }}</td>
              <td class="w100">{{ fee.money }}</td>
              <td class="w100">{{ fee.pay_target_name }}</td>
              <td class="w100">{{ fee.fee_type&&fee.fee_type===1?orderData.customer : '' }}</td>
            </tr>
            <tr v-for="fee in detail.add_fees" :key="fee.id">
              <td class="w100">{{ fee.fee_type?(fee.fee_type===1?'应收' : '应付'):'' }}</td>
              <td class="w100">{{ fee.fee_name }}</td>
              <td class="w100">{{ fee.currency_name }}</td>
              <td class="w100">{{ fee.money }}</td>
              <td class="w100">{{ fee.pay_target_name }}</td>
              <td class="w100">{{ fee.fee_type&&fee.fee_type===1?orderData.customer : '' }}</td>
            </tr>
            <tr v-for="fee in detail.add_exists_fees" :key="fee.id">
              <td class="w100">{{ fee.fee_type?(fee.fee_type===1?'应收' : '应付'):'' }}</td>
              <td class="w100">{{ fee.fee_name }}</td>
              <td class="w100">{{ fee.currency_name }}</td>
              <td class="w100">{{ fee.money }}</td>
              <td class="w100">{{ fee.pay_target_name }}</td>
              <td class="w100">{{ fee.fee_type&&fee.fee_type===1?orderData.customer : '' }}</td>
            </tr>
          </tbody>
        </table>
        <table v-if="detail.adjust_type === '修改客户'">
          <tbody>
            <tr>
              <th>原客户</th>
              <th>新客户</th>
            </tr>
            <tr>
              <td class="w100">{{ detail.original_customer_name }}</td>
              <td class="w100">{{ detail.new_customer_name }}</td>
            </tr>
          </tbody>
        </table>
        <table v-if="detail.adjust_type === '重新开票'">
          <tbody>
            <tr>
              <th>原票号</th>
              <th>新票号</th>
            </tr>
            <tr>
              <td class="w100">{{ detail.original_invoices }}</td>
              <td class="w100">{{ detail.new_invoices }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="detail.adjust_type === '作废发票'">
          <span>作废票号：{{ detail.original_invoices }}</span>
        </div>
      </div>
      <div v-if="adjust.fms_adjust_info">
        <!-- <p style="text-align: left; font-weight: 600">
          <a-divider orientation="left"> 审核信息 </a-divider>
        </p> -->
        <a-row :gutter="24">
          <a-col :span="24">
            调整原因:{{ adjust.fms_adjust_info.reason_description}}
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="4" v-for="flow in adjust.fms_adjust_info.adjust_flow_list" :key="flow.id">
            {{flow.flow_status_name}}：{{flow.flow_user_name}}-{{ moment(flow.created_at).format('YYYY/MM/DD') }}
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  components: {},
  props: {
    currentData: {
      type: Object,
      default: null
    },
    balanceData: {
      type: Array,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    },
    balanceData: {
      handler(val, oldVal) {
        this.initData()
      },
      deep: true
    }
  },
  data() {
    return {
      orderData: {},
      orderList: [],
      charge: 0,
      pay: 0,
      chargeNumber: 0,
      payNumber: 0,
      profit: 0,
      rowChargeWmsNumber: 0,
      rowChargeClearNumber: 0,
      rowChargeProxyNumber: 0,
      rowChargeRentNumber: 0,
      rowPayeWmsNumber: 0,
      rowPayClearNumber: 0,
      rowPayProxyNumber: 0,
      rowChargeWms: 0,
      rowChargeClear: 0,
      rowChargeProxy: 0,
      rowPayeWms: 0,
      rowPayClear: 0,
      rowPayProxy: 0,
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date(),
      addFeesColumns: [
        {
          title: '订单编号',
          align: 'center',
          dataIndex: 'order_num'
        },
        {
          title: '费用名称',
          align: 'center',
          dataIndex: 'fee_name'
        },
        {
          title: '金额',
          align: 'center',
          dataIndex: 'fee_amount'
        },
        {
          title: '付款单位',
          align: 'center',
          dataIndex: 'pay_target_name'
        },
        {
          title: '加入结算时间',
          align: 'center',
          dataIndex: 'create_time'
        }
      ],
      addFeeList: []
    }
  },
  methods: {
    moment,
    initData() {
      // 统计数据
      let charge = 0
      let pay = 0
      let profit = 0
      // 费用类型价钱
      let rowChargeWms = 0
      let rowChargeClear = 0
      let rowChargeProxy = 0
      let rowChargeRent = 0
      let rowPayWms = 0
      let rowPayClear = 0
      let rowPayProxy = 0
      this.balanceData.forEach(v => {
        if (v.id !== '2020') {
          let rowCharge = 0
          let rowPay = 0
          v.charge_fees &&
          v.charge_fees.forEach(c => {
            if (c.currency_name === 'CNY') {
              charge += c.amount
              rowCharge += c.amount
            } else {
              charge += c.exchange_amount
              rowCharge += c.exchange_amount
            }
          })
          v.pay_fees &&
          v.pay_fees.forEach(c => {
            if (c.currency_name === 'CNY') {
              pay += c.amount
              rowPay += c.amount
            } else {
              pay += c.exchange_amount
              rowPay += c.exchange_amount
            }
          })
          profit += v.profit
          v.rowCharge = this.moneyFormatter(rowCharge)
          v.rowPay = this.moneyFormatter(rowPay)
          if (v.fee_class_fees) {
            if (v.fee_class_fees.charge_wms_fee) {
              v.fee_class_fees.charge_wms_fee.forEach(c => {
                if (c.currency_name === 'CNY') {
                  rowChargeWms += c.amount
                } else {
                  rowChargeWms += c.exchange_amount
                }
              })
            }
            if (v.fee_class_fees.charge_clear_fee) {
              v.fee_class_fees.charge_clear_fee.forEach(c => {
                if (c.currency_name === 'CNY') {
                  rowChargeClear += c.amount
                } else {
                  rowChargeClear += c.exchange_amount
                }
              })
            }
            if (v.fee_class_fees.charge_proxy_fee) {
              v.fee_class_fees.charge_proxy_fee.forEach(c => {
                if (c.currency_name === 'CNY') {
                  rowChargeProxy += c.amount
                } else {
                  rowChargeProxy += c.exchange_amount
                }
              })
            }
            if (v.fee_class_fees.charge_rent_fee) {
              v.fee_class_fees.charge_rent_fee.forEach(c => {
                if (c.currency_name === 'CNY') {
                  rowChargeRent += c.amount
                } else {
                  rowChargeRent += c.exchange_amount
                }
              })
            }

            if (v.fee_class_fees.pay_wms_fee) {
              v.fee_class_fees.pay_wms_fee.forEach(c => {
                if (c.currency_name === 'CNY') {
                  rowPayWms += c.amount
                } else {
                  rowPayWms += c.exchange_amount
                }
              })
            }
            if (v.fee_class_fees.pay_clear_fee) {
              v.fee_class_fees.pay_clear_fee.forEach(c => {
                if (c.currency_name === 'CNY') {
                  rowPayClear += c.amount
                } else {
                  rowPayClear += c.exchange_amount
                }
              })
            }
            if (v.fee_class_fees.pay_proxy_fee) {
              v.fee_class_fees.pay_proxy_fee.forEach(c => {
                if (c.currency_name === 'CNY') {
                  rowPayProxy += c.amount
                } else {
                  rowPayProxy += c.exchange_amount
                }
              })
            }
          }
        }
      })
      this.orderData = this.currentData;
      this.orderList = this.balanceData;
      this.addFeeList = this.currentData.last_add_fees
      this.chargeNumber = charge;
      this.payNumber = pay;
      this.charge = this.moneyFormatter(charge);
      this.pay = this.moneyFormatter(pay);
      this.profit = this.moneyFormatter(profit);
      this.rowChargeWmsNumber = rowChargeWms;
      this.rowChargeClearNumber = rowChargeClear;
      this.rowChargeProxyNumber = rowChargeProxy;
      this.rowChargeRentNumber = rowChargeRent;
      this.rowPayWmsNumber = rowPayWms;
      this.rowPayClearNumber = rowPayClear;
      this.rowPayProxyNumber = rowPayProxy;

      this.rowChargeWms = this.moneyFormatter(rowChargeWms);
      this.rowChargeClear = this.moneyFormatter(rowChargeClear);
      this.rowChargeProxy = this.moneyFormatter(rowChargeProxy);
      this.rowChargeRent = this.moneyFormatter(rowChargeRent);
      this.rowPayWms = this.moneyFormatter(rowPayWms);
      this.rowPayClear = this.moneyFormatter(rowPayClear);
      this.rowPayProxy = this.moneyFormatter(rowPayProxy);
    },
    moneyFormatter(money) {
      if (typeof money !== 'number') {
        return ''
      }
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
  }
}
</script>

<style scoped>
/* .fixwidth40{ display: block;  max-width: 40px; width: 40px;}
.fixwidth50{ display: block;  max-width: 50px; width: 50px;}
.fixwidth100{ display: block;  max-width: 100px; width: 100px;} */
@page{
  size: auto;
  margin: 10mm;
}

html{
  background-color: #FFFFFF;
  margin: 0;  /* this affects the margin on the html before sending to printer */
}

/*body{*/
/*  border: solid 1px blue ;*/
/*  margin: 10mm 15mm 10mm 15mm; !* margin you want for the content *!*/
/*}*/
@media print {
  html, body {
    height: inherit;
  }
}
#print { min-width:1100px;padding-top: 20px;padding-left: 20px;padding-right: 20px }
#print p, #print table { width: 100% }
table { border-collapse: collapse; text-align: center; height: 100%;}
td, th { border: 1px solid #000; padding: 0; line-height: 20px; max-height: 40px; }
/* .w25 { width:25px ; max-width: 25px}
.w40 { width:40px ; max-width: 40px; min-width: 10px;}
.w50 { width:50px ; max-width: 50px; min-width: 10px;}
.w60 { width:60px ; max-width: 60px; min-width: 10px;}
.w70 { width:70px ; max-width: 70px; min-width: 10px;}
.w100 { width:100px }
.w200 { width:200px } */
.table2 { font-size: 12px; }
.table2 tr td:nth-child(1) { border-left: 0; }
.table2 tr td:nth-last-child(1) { border-right: 0; }
.table2 tr:nth-child(1) td{ border-top: 0; }
.table2 tr:nth-last-child(1) td{ border-bottom: 0; }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
.textRight{
  text-align: right;
  word-break: break-all; word-wrap:break-word;
}
</style>
